<template>
    <span :class="`badge badge-`+(opt || {}).style+(_events.click?' pointer':'')" @click="doClick">{{opt.label}}</span>
</template>

<script>
export default {
  props:{
    type: String,
    data: Object,
    status: String,
  },
  computed:{
    opt(){
      if(this.type=='contact'){
        return {
          'R': {label:"Read",style:"success"},
          'U': {label:"Unread",style:"warning"},
        }[this.status]
      } else if (this.type=='category'){
        return {
          'kids-dash': {label:"Kids-Dash",style:"warning"},
          '5KM': {label:"5KM",style:"warning"},
          // '5KM-group': {label:"5KM-Group",style:"warning"},
          '10KM': {label:"10KM",style:"success"},
          '21KM': {label:"21KM",style:"info"},
          '42KM': {label:"42KM",style:"danger"},
        }[this.status]
      } else if (this.type === 'spda') {
        return {
          S: { label: 'Scheduled', style: 'success' },
          P: { label: 'Publish', style: 'success' },
          D: { label: 'Draft', style: 'info' },
          A: { label: 'Archive', style: 'warning' },
        }[this.status];
      } else if (this.type=='level_partner'){
        return {
          'Platinum': {label:"Platinum",style:"success"},
          'Gold': {label:"Gold",style:"warning"},
          'Silver': {label:"Silver",style:"secondary"},
          'Bronze': {label:"Bronze",style:"secondary"},
        }[this.status]
      } else if (this.type=='featured'){
        return {
          'Y': {label:"Featured",style:"info"},
          'N': {label:"Unfeatured",style:"warning"},
        }[this.status]
      } else{
        return {
          'Y': {label:"Active",style:"success"},
          'N': {label:"Inactive",style:"warning"},
        }[this.status]
      }
    }
  },
  methods: {
    doClick(){
      this.$emit('click', this)
    }
  },
}
</script>